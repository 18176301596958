const processName = name => {
  let firstName, lastName

  // Check if the name contains spaces
  if (name.includes(" ")) {
    let nameParts = name.split(" ")
    lastName = nameParts[0]
    firstName = nameParts.slice(1).join(" ")
  } else {
    firstName = name
    lastName = ""
  }

  return { firstName, lastName }
}

export default processName
